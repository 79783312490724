import { render, staticRenderFns } from "./lib-n-supplies.vue?vue&type=template&id=5f987034&scoped=true&"
var script = {}
import style0 from "./lib-n-supplies.vue?vue&type=style&index=0&id=5f987034&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5f987034",
  null
  
)

export default component.exports