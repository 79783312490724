<template>
  <section class="home-page-section news-section">
    <div class="home-container">
      <h3 class="home-page-section-title text-center text-black-prim">
        Báo chí nói về CoLearn
      </h3>
      <VueSlickCarousel class="list-news-logo" v-bind="carouselSettings">
        <img src="/ui-new/images/news-1.png" alt="news-logo" class="news-logo-img">
        <img src="/ui-new/images/news-logo-2.png" alt="news-logo" class="news-logo-img">
        <img src="/ui-new/images/news-logo-3.png" alt="news-logo" class="news-logo-img">
        <img src="/ui-new/images/news-logo-4.png" alt="news-logo" class="news-logo-img">
        <img src="/ui-new/images/news-logo-5.png" alt="news-logo" class="news-logo-img">
        <img src="/ui-new/images/news-logo-6.png" alt="news-logo" class="news-logo-img">
      </VueSlickCarousel>
    </div>
  </section>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'

export default {
  components: {
    VueSlickCarousel
  },
  data () {
    return {
      carouselSettings: {
        dots: false,
        arrows: false,
        infinite: false,
        autoplay: true,
        pauseOnHover: true,
        speed: 500,
        slidesToShow: 6,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 5
            }
          },
          {
            breakpoint: 520,
            settings: {
              slidesToShow: 3
            }
          }
        ]
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  ::v-deep {
    .slick-track {
      display: flex !important;
      align-items: center;
    }
    .slick-initialized .slick-slide {
      display: flex !important;
      justify-content: center;
    }
  }
  .news-section {
		background: #f8f8fa;
		padding: 60px 0 65px;
		background-repeat: no-repeat;
		.home-page-section-title {
			font-weight: 800;
			font-size: 36px;
			line-height: 26px;
			margin-bottom: 44px;
		}
		.list-news-logo {
      grid-gap: 64px;
      gap: 64px;
			.news-logo-img {
        width: auto !important;
				max-height: 68px;
        padding: 0 10px;
        margin: auto;
			}
		}
    @media only screen and (max-width: 576px) {
      padding: 32px 0 56px;
      display: block;
      .home-page-section-title {
        font-size: 24px;
        line-height: 26px;
        margin-bottom: 23px;
      }
    }
	}
</style>
