<template>
  <div>
    <!-- <section class="home-page-section online-class-section bg-white">
      <div class="home-container">
        <h3 class="home-page-section-title text-center text-black-prim">
          Lớp học trực tuyến
        </h3>
        <div class="row">
          <div class="col-6 rs d-flex flex-column ">
            <h4 class="step-title font-weight-bold">
              Lớp học nhỏ (sĩ số &lt;5 học sinh/lớp)
            </h4>
            <div class="each-step">
              <input type="radio" class="lh1-radio" preview-image="lh1-1.png" id="online-class-r-1" name="online-group" checked>
              <label for="online-class-r-1" class="position-relative mb-0">
                <div class="divider-h position-absolute"></div>
                <p class="step-text text-black-prim">
                  Cố vấn học tập 1 kèm 1 giúp xây dựng kế hoạch học tập riêng cho từng học sinh theo đúng nguyện vọng
                </p>
              </label>
            </div>
            <div class="each-step">
              <input type="radio" class="lh1-radio" preview-image="lh1-2.png" id="online-class-r-2" name="online-group">
              <label for="online-class-r-2" class="position-relative mb-0">
                <div class="divider-h position-absolute"></div>
                <p class="step-text text-black-prim">
                  Lớp học được phân chia theo học lực của học sinh
                </p>
              </label>
            </div>
            <div class="each-step">
              <input type="radio" class="lh1-radio" preview-image="lh1-3.png" id="online-class-r-3" name="online-group">
              <label for="online-class-r-3" class="position-relative mb-0">
                <div class="divider-h position-absolute"></div>
                <p class="step-text text-black-prim">
                  Hướng dẫn phương pháp tự học hiệu quả tại nhà
                </p>
              </label>
            </div>
            <div class="each-step">
              <input type="radio" class="lh1-radio" preview-image="lh1-4.png" id="online-class-r-4" name="online-group">
              <label for="online-class-r-4" class="position-relative mb-0">
                <div class="divider-h position-absolute"></div>
                <p class="step-text text-black-prim">
                  Giáo viên theo sát từng bạn, đảm bảo học chắc kiến thức, nhớ lâu, vận dụng tốt trong các kỳ thi
                </p>
              </label>
            </div>
          </div>
          <div class="col-6 ls">
            <img src="/ui-new/images/online-class-image-col.png" alt="online-class-image-col" class=" mw-100 d-block mx-auto radio-banner" id="lh1-preview-image">
          </div>
        </div>
      </div>
    </section>
    <section class="home-page-section online-class-section section-2">
      <div class="home-container">
        <div class="row">
          <div class="col-6 ls mt-4">
            <img id="lh2-preview-image" src="/ui-new/images/online-class-image-col-b2.png" alt="online-class-image-col" class=" mw-100 d-block mx-auto radio-banner">
          </div>
          <div class="col-6 rs d-flex flex-column ">
            <h4 class="step-title font-weight-bold">
              Lớp học tiêu chuẩn (sĩ số 10 - 15 học sinh/lớp)
            </h4>
            <div class="each-step">
              <input type="radio" class="lh2-radio" preview-image="lh2-1.png" id="online-classb2-r-1" name="online-group2" checked>
              <label for="online-classb2-r-1" class="position-relative mb-0">
                <div class="divider-h position-absolute"></div>
                <p class="step-text text-black-prim">
                  Cố vấn học tập giúp xây dựng lộ trình và kế hoạch học tập rõ ràng
                </p>
              </label>
            </div>
            <div class="each-step">
              <input type="radio" class="lh2-radio" preview-image="lh2-2.png" id="online-classb2-r-2" name="online-group2">
              <label for="online-classb2-r-2" class="position-relative mb-0">
                <div class="divider-h position-absolute"></div>
                <p class="step-text text-black-prim">
                  Lớp học được phân chia theo học lực của học sinh
                </p>
              </label>
            </div>
            <div class="each-step">
              <input type="radio" class="lh2-radio" preview-image="lh2-3.png" id="online-classb2-r-3" name="online-group2">
              <label for="online-classb2-r-3" class="position-relative mb-0">
                <div class="divider-h position-absolute"></div>
                <p class="step-text text-black-prim">
                  Giáo viên đồng hành và giải đáp toàn bộ thắc mắc trong quá trình học
                </p>
              </label>
            </div>
            <div class="each-step">
              <input type="radio" class="lh2-radio" preview-image="lh2-4.png" id="online-classb2-r-4" name="online-group2">
              <label for="online-classb2-r-4" class="position-relative mb-0">
                <div class="divider-h position-absolute"></div>
                <p class="step-text text-black-prim">
                  Được kiểm tra, đánh giá thường xuyên để điều chỉnh lộ trình học tập phù hợp
                </p>
              </label>
            </div>
            <div class="each-step">
              <input type="radio" class="lh2-radio" preview-image="lh2-5.png" id="online-classb2-r-5" name="online-group2">
              <label for="online-classb2-r-5" class="position-relative mb-0">
                <div class="divider-h position-absolute"></div>
                <p class="step-text text-black-prim">
                  Học Online không mất thời gian đi lại
                </p>
              </label>
            </div>
          </div>
        </div>
      </div>
    </section> -->
    <section class="home-page-section online-class-section section-3 bg-white">
      <div class="home-container">
        <h4 class="pl-5 font-weight-bold">Thư viện và công cụ học tập</h4>
        <div class="row">
          <div class="col-6 rs d-flex flex-column justify-content-center ">
            <div class="padding-content">
              <label class="position-relative mb-0 ">
                <p class="step-title-sub1 text-black-prim font-weight-bold">
                  Kho học liệu
                </p>
                <p class="step-desc text-black-prim font-weight-normal">
                  Cung cấp kho thư viện nội dung lớn, đa dạng tất cả các môn và các khối lớp
                </p>
              </label>
              <div class="each-step">
                <input type="radio" class="lh3-radio" preview-image="lib-avg.png" id="online-classb3-r-1"  checked>
                <label for="online-classb3-r-1" class="position-relative mb-0">
                  <div class="divider-h position-absolute"></div>
                  <p class=" step-text text-black-prim">
                    Thư viện video
                  </p>
                  <p class="step-desc text-black-prim font-weight-normal">
                    Bài giảng ngắn (microlearning) được nhóm thành các chuyên đề - khóa học từ cơ bản đến nâng cao, được cập nhật và sản xuất mới.
                  </p>
                </label>
              </div>
              <div class="each-step">
                <input type="radio"  class="lh3-radio" preview-image="lib-avg.png" id="online-classb3-r-2"  checked>
                <label for="online-classb3-r-2" class="position-relative mb-0">
                  <div class="divider-h position-absolute"></div>
                  <p class="step-text text-black-prim">
                    Thư viện tài liệu
                  </p>
                  <p class="step-desc text-black-prim font-weight-normal">
                    Ebook, tài liệu bộ đề, tài liệu tham khảo được biên soạn độc quyền, hợp tác.
                  </p>
                </label>
              </div>
              <div class="each-step">
                <input type="radio" class="lh3-radio" preview-image="lib-avg.png" id="online-classb3-r-3"  checked>
                <label for="online-classb3-r-3" class="position-relative mb-0">
                  <div class="divider-h position-absolute"></div>
                  <p class="step-text text-black-prim">
                    Trắc nghiệm
                  </p>
                  <p class="step-desc text-black-prim font-weight-normal">
                    Bộ bài thi trắc nghiệm online cho phép học sinh làm bài, xem kết quả với lời giải chi tiết.
                  </p>
                </label>
              </div>
            </div>
          </div>
          <div class="col-6 ls">
            <img id="lh3-preview-image" src="/ui-new/images/lib-avg.png" alt="online-class-image-col" class="mw-100 d-block mx-auto radio-banner">
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data () {
    return {
      listActive0: 0,
      listActive1: 0,
      listActive2: 0,
      listActive3: 0,
      myInterval: null
    }
  },
  beforeDestroy () {
    if (this.myInterval) {
      clearInterval(this.myInterval)
    }
  }
  // mounted () {
  //   const listLh0 = [...document.querySelectorAll('.lh0-radio')]
  //   const listLh1 = [...document.querySelectorAll('.lh1-radio')]
  //   const listLh2 = [...document.querySelectorAll('.lh2-radio')]
  //   const listLh3 = [...document.querySelectorAll('.lh3-radio')]
  //   const previewLh0 = document.querySelector('#lh0-preview-image')
  //   const previewLh1 = document.querySelector('#lh1-preview-image')
  //   const previewLh2 = document.querySelector('#lh2-preview-image')
  //   const previewLh3 = document.querySelector('#lh3-preview-image')
  //   listLh0.forEach((o) => {
  //     if (o.checked) {
  //       previewLh0.setAttribute('src', `/ui-new/images/${o.getAttribute('preview-image')}`)
  //     }
  //     o.addEventListener('change', () => {
  //       this.listActive0 = listLh0.findIndex(o => o.checked)
  //       previewLh0.classList.add('animate-left')
  //       previewLh0.setAttribute('src', `/ui-new/images/${o.getAttribute('preview-image')}`)
  //       setTimeout(() => {
  //         previewLh0.classList.remove('animate-left')
  //       }, 1000)
  //     })
  //   })
  //   listLh1.forEach((o) => {
  //     if (o.checked) {
  //       previewLh1.setAttribute('src', `/ui-new/images/${o.getAttribute('preview-image')}`)
  //     }
  //     o.addEventListener('change', () => {
  //       this.listActive1 = listLh1.findIndex(o => o.checked)
  //       previewLh1.classList.add('animate-top')
  //       previewLh1.setAttribute('src', `/ui-new/images/${o.getAttribute('preview-image')}`)
  //       setTimeout(() => {
  //         previewLh1.classList.remove('animate-top')
  //       }, 1000)
  //     })
  //   })
  //   listLh2.forEach((o) => {
  //     if (o.checked) {
  //       previewLh2.setAttribute('src', `/ui-new/images/${o.getAttribute('preview-image')}`)
  //     }
  //     o.addEventListener('change', () => {
  //       this.listActive2 = listLh2.findIndex(o => o.checked)
  //       previewLh2.classList.add('animate-right')
  //       previewLh2.setAttribute('src', `/ui-new/images/${o.getAttribute('preview-image')}`)
  //       setTimeout(() => {
  //         previewLh2.classList.remove('animate-right')
  //       }, 1000)
  //     })
  //   })
  //   listLh3.forEach((o) => {
  //     if (o.checked) {
  //       previewLh3.setAttribute('src', `/ui-new/images/${o.getAttribute('preview-image')}`)
  //     }
  //     o.addEventListener('change', () => {
  //       this.listActive3 = listLh3.findIndex(o => o.checked)
  //       previewLh3.classList.add('animate-zoom')
  //       previewLh3.setAttribute('src', `/ui-new/images/${o.getAttribute('preview-image')}`)
  //       setTimeout(() => {
  //         previewLh3.classList.remove('animate-zoom')
  //       }, 1000)
  //     })
  //   })
  //   this.myInterval = setInterval(() => {
  //     this.listActive0++
  //     this.listActive1++
  //     this.listActive2++
  //     this.listActive3++
  //     if (this.listActive0 === listLh0.length) {
  //       this.listActive0 = 0
  //     }
  //     if (this.listActive1 === listLh1.length) {
  //       this.listActive1 = 0
  //     }
  //     if (this.listActive2 === listLh2.length) {
  //       this.listActive2 = 0
  //     }
  //     if (this.listActive3 === listLh3.length) {
  //       this.listActive3 = 0
  //     }
  //     listLh0[this.listActive0].click()
  //     listLh1[this.listActive1].click()
  //     listLh2[this.listActive2].click()
  //     listLh3[this.listActive3].click()
  //   }, 15 * 1000)
  // }
}
</script>

<style lang="scss">
  @keyframes animatetop{from{top:-300px;opacity:0} to{top:0;opacity:1}}
  .animate-top{position:relative;animation:animatetop 0.4s}
  @keyframes animateleft{from{left:-300px;opacity:0} to{left:0;opacity:1}}
  .animate-left{position:relative;animation:animateleft 0.4s}
  @keyframes animateright{from{right:-300px;opacity:0} to{right:0;opacity:1}}
  .animate-right{position:relative;animation:animateright 0.4s}
  @keyframes animatebottom{from{bottom:-300px;opacity:0} to{bottom:0;opacity:1}}
  .animate-bottom{position:relative;animation:animatebottom 0.4s}
  @keyframes animatezoom{from{transform:scale(0)} to{transform:scale(1)}}
  .animate-zoom {animation:animatezoom 0.6s}
</style>
<style lang="scss" scoped>
  .padding-content{
    padding-left: 15%;
  }
  .online-class-section {
		padding: 55px 0 105px;
		background-color: #f8f8fa;
		&.section-2 {
			padding: 76px 0 105px;
      @media only screen and (max-width: 992px) {
        padding: 45px 0 40px;
        .ls {
          -webkit-box-ordinal-group: 2;
          order: 1;
          margin-top: 32px !important;
        }
      }
      @media only screen and (max-width: 576px) {
        padding: 16px 0 26px;
        .ls {
          -webkit-box-ordinal-group: 2;
          order: 1;
          margin-top: 19px !important;
        }
      }
		}
		&.section-3 {
       @media only screen and (max-width: 992px) {
        padding: 45px 0 40px;
        .ls {
          margin-top: 19px !important;
        }
      }
		}
    .radio-banner {
			filter: drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.1));
			border-radius: 16px;
      transition: all .2s;
		}
		.home-page-section-title {
			font-weight: 800;
			font-size: 36px;
			line-height: 24px;
			margin-bottom: 80px;
		}
		.rs {
			padding-top: 16px;
			.step-title {
				padding-left: 51px;
				font-size: 24px;
				line-height: 26px;
				color: #4643f0;
				margin-bottom: 42px;
        @media (max-width:1366px) {
          margin-bottom: 24px;
        }
        @media (max-width:992px) {
        }
			}
      .step-title-sub1 {
        font-size: 20px;
        color: #4643f0;
        @media (max-width:1366px) {
          margin-bottom: 24px;
        }
        @media (max-width:992px) {
        }
      }
			.each-step {
				.step-text {
					font-weight: 400;
					font-size: 18px;
					margin: 0;
					line-height: 26px;
					padding: 12px 0;
          @media (max-width:1366px) {
            line-height: 20px;
            padding: 10px 0;
          }
          @media (max-width:992px) {
          }
				}
        .step-desc {
          font-size: 18px;
          line-height: 26px;
          margin-bottom: 40px;
        }
				.divider-h {
					width: 1px;
					height: 100%;
					background-color: #cbcbcb;
					left: 10px;
					top: 0;
					z-index: 3;
				}
				&:nth-last-of-type(1) {
					.divider-h {
						top: 0;
						height: 70%;
					}
				}
				&:nth-of-type(1) {
					.divider-h {
						top: 25%;
					}
				}
			}
		}
		[type='radio']:checked,
		[type='radio']:not(:checked) {
			position: absolute;
			left: -9999px;
		}
		[type='radio']:checked + label,
		[type='radio']:not(:checked) + label {
			position: relative;
			padding-left: 51px;
			cursor: pointer;
			line-height: 20px;
			display: inline-block;
		}
		[type='radio']:checked + label {
			.step-text {
				font-weight: 700;
			}
		}
		[type='radio']:not(:checked) + label:before {
			display: none;
		}
    .radio-style{
      z-index: 4;
      content: '';
      position: absolute;
      left: 0;
      top: 25%;
      transform: translateY(-50%);
      width: 21px;
      height: 21px;
      border: 2px solid #4643f0;
      border-radius: 100%;
      background: #fff;
    }
		[type='radio']:checked + label:before {
			z-index: 4;
			content: '';
			position: absolute;
			left: 0;
			top: 25%;
			transform: translateY(-50%);
			width: 21px;
			height: 21px;
			border: 2px solid #4643f0;
			border-radius: 100%;
			background: #fff;
		}
		[type='radio']:not(:checked) + label:after {
			z-index: 4;
			content: '';
			width: 11px;
			height: 11px;
			background: #cbcbcb;
			position: absolute;
			top: 25%;
			transform: translateY(-50%);
			left: 5px;
			border-radius: 100%;
			-webkit-transition: all 0.2s ease;
			transition: all 0.2s ease;
		}
		[type='radio']:checked + label:after {
			z-index: 4;
			content: '';
			width: 11px;
			height: 11px;
			background: #4643f0;
			position: absolute;
			top: 25%;
			transform: translateY(-50%);
			left: 5px;
			border-radius: 100%;
			-webkit-transition: all 0.2s ease;
			transition: all 0.2s ease;
		}
		[type='radio']:checked + label:after {
			opacity: 1;
		}
    @media only screen and (max-width: 992px) {
      padding: 32px 0 49px;
      .home-page-section-title {
        font-weight: 800;
        font-size: 28px;
        line-height: 28px;
        margin-bottom: 32px;
      }
      .rs {
        padding: 0;
        -webkit-box-flex: 0;
        flex: 0 0 100%;
        max-width: 100%;
        .step-title {
          padding-left: 0px;
          font-weight: 700;
          font-size: 18px;
          line-height: 26px;
          margin-bottom: 20px;
        }
        .each-step .step-text {
          font-weight: 400;
          font-size: 16px;
          line-height: 26px;
        }
      }
      .ls {
        margin-top: 60px;
        padding: 0;
        -webkit-box-flex: 0;
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
    @media only screen and (max-width: 576px) {
      padding: 32px 0 50px;
      .home-page-section-title {
        font-size: 24px;
        line-height: 24px;
        margin-bottom: 24px;
      }
      [type='radio']:not(:checked) + label, [type='radio']:checked + label {
        padding-left: 28px;
      }
      .rs {
        -webkit-box-flex: 0;
        flex: 0 0 100%;
        max-width: 100%;
        .step-title {
          font-size: 16px;
          line-height: 18px;
          margin-bottom: 24px;
        }
        .each-step .step-text {
          font-size: 14px;
          line-height: 18px;
        }
      }
      .ls {
        margin-top: 26px;
        max-width: 100%;
      }
    }
  }
</style>
