<template>
  <section class="home-page-section comments-section" style="background-image: url('/ui-new/images/comments-bg.png');">
    <div class="home-container">
      <h3 class="home-page-section-title text-center text-white">
        Học sinh nói gì về Vilearn 😍
      </h3>
      <VueSlickCarousel v-bind="carouselSettings">
        <div class="cmt-col cmt-col-1">
          <div class="comment-card">
            <div class="text-block bg-white">
              <p class="desc text-center">Web rất tiện ích, vừa có video lại có cả bài tập đồng thời có giải bài tập với gia sư. Lời giải bài tập rất chi tiết có chú ý sai lầm giúp em học hiệu quả.</p>
            </div>
            <div class="user-block d-flex align-items-center justify-content-center mt-4">
              <img src="/ui-new/images/user-avatar-2.jpg" alt="user-avatar" class="rounded-circle" width="62px" height="62px">
              <div class="user-info">
                <p class="user-name text-white mb-0">Đỗ Hoàng Nguyên</p>
                <p class="user-pos text-white mb-0">THPT Đô Lương II</p>
              </div>
            </div>
          </div>
        </div>
        <div class="cmt-col cmt-col-2">
          <div class="comment-card">
            <div class="text-block bg-white">
              <p class="desc text-center">Em cảm thấy trang web này rất hay và rất bổ ích, cần phải giới thiệu trang web này để nhiều học sinh của nhiều trường được biết hơn.</p>
            </div>
            <div class="user-block d-flex align-items-center justify-content-center mt-4">
              <img src="/ui-new/images/user-avatar-1.png" alt="user-avatar" class="rounded-circle" width="62px" height="62px">
              <div class="user-info">
                <p class="user-name text-white mb-0">Hoàng Yến Nhi</p>
                <p class="user-pos text-white mb-0">THPT Chuyên Thoại Ngọc Hầu</p>
              </div>
            </div>
          </div>
        </div>
      </VueSlickCarousel>
    </div>
  </section>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'

export default {
  components: {
    VueSlickCarousel
  },
  data () {
    return {
      carouselSettings: {
        dots: true,
        arrows: false,
        infinite: false,
        autoplay: true,
        pauseOnHover: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 576,
            settings: {
              slidesToShow: 1
            }
          }
        ]
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  ::v-deep .slick-dots {
    bottom: -35px;
    li {
      margin: 0;
      height: 25px;
      button::before {
        font-size: 10px;
        color: #fff;
        opacity: 0.25;
      }
      &.slick-active button::before {
        font-size: 10px;
        color: #fff;
        opacity: 0.75;
      }
    }
  }
  .comments-section {
		background: #fb8e0b;
		padding: 56px 0;
		background-repeat: no-repeat;
		.home-page-section-title {
			font-size: 36px;
			line-height: 56px;
			color: #1b1c20;
			margin-bottom: 53px;
      font-weight: 800;
		}
		.comment-card {
			.text-block {
				max-width: 600px;
				border-radius: 14px;
				padding: 30px 42px;
				position: relative;
				.title {
					font-size: 20px;
					line-height: 28px;
					color: #fd6003;
				}
				.desc {
					font-size: 18px;
					line-height: 28px;
					color: rgba(56, 58, 71, 0.7);
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 5;
          -webkit-box-orient: vertical;
				}
				&::after {
					position: absolute;
					content: '';
					border-top: 14px solid #fff;
					border-left: 14px solid transparent;
					border-bottom: 14px solid transparent;
					border-right: 14px solid transparent;
					bottom: 0;
					left: 50%;
					transform: translateX(-50%) translateY(100%);
				}
			}
			.user-block {
				gap: 18px;
        img {
          object-fit: cover;
          object-position: top;
        }
				.user-info {
					.user-name {
						font-weight: 600;
						font-size: 18px;
						line-height: 30px;
					}
					.user-pos {
						font-size: 14px;
						line-height: 24px;
					}
				}
			}
		}
    @media only screen and (max-width: 992px) {
      padding-top: 15px;
      padding-bottom: 70px;
      .home-page-section-title {
        font-size: 28px;
        margin-bottom: 40px;
      }
      .cmt-col {
        .comment-card {
          .text-block {
            padding: 20px 8px 18px;
            height: 150px;
          }
          .title {
            font-size: 16px;
            margin-bottom: 6px !important;
          }
          .desc {
            font-size: 16px;
            line-height: 28px;
          }
        }
      }
    }
    @media only screen and (max-width: 576px) {
      .home-page-section-title {
        font-size: 24px;
      }
    }
  }
</style>
<style lang="scss">
  .comments-section{
    .slick-slider{
      .slick-list{
        --size: 40px;
        margin:0 calc(var(--size) * -1);
        @media (max-width: 576px) {
          --size: 10px;
        }
        @media (max-width: 768px) {
          --size: 10px;
        }
        @media (max-width: 992px) {}
        @media (max-width: 1200px) {}
        .slick-track{
          .slick-slide{
            padding: 0 var(--size);
          }
        }
      }
    }
  }
</style>
